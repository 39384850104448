import React from "react";
import HeaderSection from "./header";
import ContactSection from "../Contact";
import ReactGA from 'react-ga';

class SuporteSection extends React.Component {

  componentDidMount() {
    ReactGA.event({
      category: 'Suporte',
      action: 'Entrou na página de suporte'
    });
  }

  render() {
    return (
      <React.Fragment>
        <HeaderSection />
        <ContactSection />
      </React.Fragment>
    );
  }
}

export default SuporteSection;
