import React from "react";
import { connect } from "react-redux";

import logo from '../../assets/img/logo/logo-gestor.png'
import logoBranca from '../../assets/img/logo/logo-gestor-branca.png'

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav className={"navbar navbar-expand-lg fixed-top " + (this.props.bgColor && this.props.bgColor === 'white' ? 'custom-nav white-bg' : 'bg-transparent')}>
            <div className="container">
              <a className="navbar-brand" href="/">
                <img
                  src={this.props.bgColor && this.props.bgColor === 'white' ? logo : logoBranca}
                  width="250"
                  alt="logo"
                  className="img-fluid"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="/">
                      Início
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link page-scroll" href="/sobre">
                      Sobre
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a className="nav-link page-scroll" href="https://blog.agrare.com.br/" target="_blank" rel="noopener noreferrer">
                      Blog
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a className="nav-link page-scroll" href="https://agraretech.com.br/" target="_blank" rel="noopener noreferrer">
                      AgrareTech
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="/suporte">
                      Suporte
                    </a>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <a className="nav-link page-scroll dropdown-toggle" href="/#" id="navbarBlogPage" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Outros Produtos
                        </a>

                    <div className="dropdown-menu submenu" aria-labelledby="navbarBlogPage">
                      <a className="dropdown-item" href="https://appgadobom.com.br/" target="_blank" onClick={() => {
                        ReactGA.event({
                          category: 'App Gado Bom',
                          action: 'Entrou na página Gado Bom'
                        });
                       }}>Aplicativo Gado Bom</a>
                      <a className="dropdown-item" href="https://app.portalpampas.com.br/" target="_blank" onClick={() => {
                        ReactGA.event({
                          category: 'App PampaS',
                          action: 'Entrou na página PampaS'
                        });
                       }}>Aplicativo Pampa S</a>
                    </div>
                  </li> */}
                  {/* <li className="nav-item">
                    <a className="nav-link page-scroll" href="https://www.blog.agrare.com.br" target="_blank">
                      Blog
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link btnAccess" href="https://agrare.app" target="_blank">
                      ENTRAR NA FAZENDA
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Header);
