import React from "react";
import { Link } from "react-router-dom";
import _data from "../../data";

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section
            id="pricing"
            className={"package-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : 'background-shape-right')} >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="section-heading text-center mb-5">
                    {/* {this.props.showTitle && this.props.showTitle === true && (
                      <span className="badge badge-primary badge-pill">
                        Our Pricing Package
                      </span>
                    )} */}
                    <h2>
                      Agrare do seu jeito <br />
                      O Melhor Custo X Benefício do mercado! <br />
                      <span>Escolha a opção é ideal para sua fazenda.</span>
                    </h2>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-4 col-md">
                  <div className="card text-center single-pricing-pack p-5">
                    <h5 className="mb-2">Digital</h5>
                    <span>Para fazendas que desejam ter os benefícios da tecnologia.</span>
                    <hr />
                    <div className="card-body p-0">
                      <ul className="list-unstyled text-sm pricing-feature-list">
                        <li>Gestão para 1 Fazenda</li>
                        <li>Até 5 Usuários</li>
                        <li>Até 500 Hectares</li>
                        <li>Central Web</li>
                        <li>Aplicativo Sem Internet</li>
                        <li>Agricultura</li>
                        <li>Estoque</li>
                        <li>Financeiro</li>
                        <li>Pluviômetro</li>
                        <li style={{ textDecoration: 'line-through' }}>Automação Financeiro</li>
                        <li style={{ textDecoration: 'line-through' }}>Monitoramento Fiscal Nota de Compras</li>
                        {/* <li>Automação Financeiro</li> */}
                        {/* <li>LCFPR</li> */}
                        {/* <li>NFe Produtor</li> */}
                        <li>Suporte WhatsApp</li>
                        <li>Até 2 Treinamentos por mês</li>
                      </ul>
                      <div className="py-4 border-0 pricing-header">
                        <div className="h1 text-center mb-0 color-secondary"><span className="price font-weight-bolder">CONSULTE</span></div>
                        {/* <div className="h1 text-center mb-0 color-secondary">R$<span className="price font-weight-bolder">108,90</span>/mês</div> */}
                        <span>Contrato Anual</span>
                      </div>
                      {/* <a href="https://agrare.app/register" className="btn outline-btn">Testar GRÁTIS</a> */}
                      {/* <Link to="/register/digital" className="btn outline-btn">Experimente Grátis</Link> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md">
                  <div className="card text-center popular-price single-pricing-pack p-5">
                    <h5 className="mb-2">Tech</h5>
                    <span>Para fazendas que desejam ter insumo para crescer seu negócio.</span>
                    <hr />
                    <div className="card-body p-0">
                      <ul className="list-unstyled text-sm pricing-feature-list">
                        <li>Fazendas Personalizadas</li>
                        <li>Até 10 Usuários</li>
                        <li>Hectares Personalizados</li>
                        <li>Central Web</li>
                        <li>Aplicativo Sem Internet</li>
                        <li>Agricultura</li>
                        <li>Estoque</li>
                        <li>Financeiro</li>
                        <li>Pluviômetro</li>
                        <li>Automação Financeiro</li>
                        <li>Monitoramento Fiscal Nota de Compras</li>
                        {/* <li>Automação Financeiro</li> */}
                        {/* <li>LCFPR</li> */}
                        {/* <li>NFe Produtor</li> */}
                        <li>Suporte WhatsApp</li>
                        <li>Treinamentos Personalizados</li>
                      </ul>
                      <div className="py-4 border-0 pricing-header">
                        <div className="h1 text-center mb-0 color-secondary"><span className="price font-weight-bolder">CONSULTE</span></div>
                        {/* <div className="h1 text-center mb-0 color-secondary">R$<span className="price font-weight-bolder">317,90</span>/mês</div> */}
                        <span>Contrato Anual</span>
                      </div>
                      {/* <a href="https://agrare.app/register" className="btn outline-btn">Testar GRÁTIS</a> */}
                      {/* <Link to="/register/tech" className="btn outline-btn">Experimente Grátis</Link> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md">
                  <div className="card text-center single-pricing-pack p-5">
                    <h5 className="mb-2">PRO</h5>
                    <span>Para fazendas que desejam maior agilidade e crescimento.</span>
                    <hr />
                    <div className="card-body p-0">
                      <ul className="list-unstyled text-sm pricing-feature-list">
                        <li>Fazendas Personalizadas</li>
                        <li>Usuários Personalizados</li>
                        <li>Hectares Personalizados</li>
                        <li>Central Web</li>
                        <li>Aplicativo Sem Internet</li>
                        <li>Agricultura</li>
                        <li>Estoque</li>
                        <li>Financeiro</li>
                        <li>Pluviômetro</li>
                        <li>Automação Financeiro</li>
                        <li>Monitoramento Fiscal Nota de Compras</li>
                        {/* <li>Automação Financeiro</li> */}
                        {/* <li>LCFPR</li> */}
                        {/* <li>NFe Produtor</li> */}
                        <li>Suporte WhatsApp</li>
                        <li>Treinamentos Personalizados</li>
                      </ul>
                      <div className="py-4 border-0 pricing-header">
                        <div className="h1 text-center mb-0 color-secondary"><span className="price font-weight-bolder">CONSULTE</span></div>
                        {/* <div className="h1 text-center mb-0 color-secondary">R$<span className="price font-weight-bolder">537,90</span>/mês</div> */}
                        <span>Contrato Anual</span>
                      </div>
                      {/* <Link to="/register/pro" className="btn outline-btn">Experimente Grátis</Link> */}
                      {/* <a href="https://agrare.app/register" className="btn outline-btn">Testar GRÁTIS</a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p className="mb-2">
                  Ficou com dúvidas ou precisa personalizar ainda mais seu sistema?{" "}
                  <a href="#contact" className="color-secondary">
                    Entre em contato conosco
                  </a>
                </p>
              </div>

              {!(this.props.hideFaq && this.props.hideFaq === true) && this.props.theme !== "four" && (
                <div className="row mt-5">
                  <div className="col-lg-6">
                    <div id="accordion-1" className="accordion accordion-faq">
                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-1-3"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-1-3"
                          aria-expanded="false"
                          aria-controls="collapse-1-3"
                        >
                          <h6 className="mb-0">
                            <span className="ti-medall mr-3"></span> Existe bônus por indicação?
                          </h6>
                        </div>
                        <div
                          id="collapse-1-3"
                          className="collapse"
                          aria-labelledby="heading-1-3"
                          data-parent="#accordion-1"
                        >
                          <div className="card-body">
                            <p>
                              Sim, oferecemos bônus por indicação. Aproveite os benefícios!
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-2-1"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-2-1"
                          aria-expanded="false"
                          aria-controls="collapse-2-1"
                        >
                          <h6 className="mb-0">
                            <span className="ti-receipt mr-3"></span> Quais são as formas de pagamento?
                          </h6>
                        </div>
                        <div
                          id="collapse-2-1"
                          className="collapse"
                          aria-labelledby="heading-2-1"
                          data-parent="#accordion-2"
                        >
                          <div className="card-body">
                            <p>
                              Você poderá escolher pagar tanto via PIX, boleto ou via cartão de crédito.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div id="accordion-2" className="accordion accordion-faq">
                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-2-2"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-2-2"
                          aria-expanded="false"
                          aria-controls="collapse-2-2"
                        >
                          <h6 className="mb-0">
                            <span className="ti-cloud-up mr-3"></span> É feito backup automático dos dados da plataforma?
                          </h6>
                        </div>
                        <div
                          id="collapse-2-2"
                          className="collapse"
                          aria-labelledby="heading-2-2"
                          data-parent="#accordion-2"
                        >
                          <div className="card-body">
                            <p>
                              Sim. Nossos servidores estão programados para realizar automaticamente o backup diariamente, estando seus dados seguros nos
                              servidores da Microsoft.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header py-4"
                          id="heading-2-3"
                          data-toggle="collapse"
                          role="button"
                          data-target="#collapse-2-3"
                          aria-expanded="false"
                          aria-controls="collapse-2-3"
                        >
                          <h6 className="mb-0">
                            <span className="ti-heart mr-3"></span> Pagamento À Vista tem desconto?
                          </h6>
                        </div>
                        <div
                          id="collapse-2-3"
                          className="collapse"
                          aria-labelledby="heading-2-3"
                          data-parent="#accordion-2"
                        >
                          <div className="card-body">
                            <p>
                              Sim. Você ganhará desconto para pagamento À Vista, ou se preferir, pagar o valor normal em até 12 vezes.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!(this.props.hideFaq && this.props.hideFaq === true) && this.props.theme === "four" && (
                <div className="row pt-5">
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>How can I pay for this?</h5>
                      <p>
                        Intrinsicly implement high standards in strategic theme
                        areas via inexpensive solutions. Assertively
                        conceptualize prospective bandwidth whereas client-based
                        imperatives.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>Is it possible to pay yearly?</h5>
                      <p>
                        Assertively iterate user friendly innovation without
                        open-source markets. Monotonectally extend resource
                        sucking manufactured products without high-payoff
                        paradigms. Objectively customize ubiquitous information
                        before economically sound relationships.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>Do you offer discounts on multiple items?</h5>
                      <p>
                        Dramatically target focused testing procedures after
                        holistic ideas. Collaboratively maximize high-payoff ROI
                        and value-added products. Distinctively deliver
                        cooperative collaboration and idea-sharing whereas
                        customized
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>Is VAT included in plan prices?</h5>
                      <p>
                        Distinctively simplify high-quality initiatives for
                        highly efficient applications. Monotonectally repurpose
                        integrated customer service after magnetic e-services.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>Will I pay more for some features?</h5>
                      <p>
                        Enthusiastically pontificate resource-leveling supply
                        chains whereas scalable markets. Authoritatively
                        streamline resource maximizing methods of empowerment
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-faq">
                      <h5>
                        Why are there no limits on the number of messages?
                      </h5>
                      <p>
                        Assertively target turnkey ideas for market-driven
                        portals. Appropriately e-enable world-className intellectual
                        capital whereas 2.0 mindshare.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
