import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div id="features" className={"feature-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <span className="badge badge-primary badge-pill">
                    Caderno de Campo Sem Internet
                  </span>
                  <h2>
                    Mais Agilidade <br />
                    <span>no seu dia-a-dia</span>
                  </h2>
                  <p>
                    Nossa plataforma online está ao seu alcance de qualquer lugar e, com nossos aplicativos para Android e iPhone, você tem a liberdade de trabalhar sem internet diretamente da lavoura, simplificando os registros no seu caderno de campo assim que as atividades são realizadas.
                  </p>
                </div>
              </div>
            </div>

            <div className="row row-grid align-items-center">
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-1 rounded-circle">
                      <span className="ti-cloud-down"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Funciona Sem Internet</h5>
                    <p className="mb-0">
                      Registre atividades no seu caderno de campo sem depender de conexão à internet.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-2 rounded-circle">
                      <span className="ti-mobile"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Celular Android ou iPhone</h5>
                    <p className="mb-0">
                      Nossos aplicativos funcionam perfeitamente em smartphones Android e iPhone.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-3 rounded-circle">
                      <span className="ti-view-list-alt"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Consultar Estoque</h5>
                    <p className="mb-0">
                      Verifique o estoque de insumos e produção agrícola diretamente do campo, sem atrasos.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="position-relative" style={{ zIndex: 10 }}>
                  <img
                    alt="placeholder"
                    src="img/03.png"
                    className="img-center img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-3 rounded-circle">
                      <span className="ti-agenda"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Planejar e Executar</h5>
                    <p className="mb-0">
                      Elabore planos agrícolas e execute manejos práticos no seu caderno de campo, tudo em um só lugar.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start mb-5">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-3 rounded-circle">
                      <span className="ti-clipboard"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Colher</h5>
                    <p className="mb-0">
                      Documente as colheitas por área diretamente no aplicativo, otimizando o controle de produção.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="pr-4">
                    <div className="icon icon-shape icon-color-3 rounded-circle">
                      <span className="ti-wallet"></span>
                    </div>
                  </div>
                  <div className="icon-text">
                    <h5>Lançar Despesas e Receitas</h5>
                    <p className="mb-0">
                      Registre despesas e receitas instantaneamente, garantindo um controle financeiro atualizado e eficiente.
                    </p>
                  </div>
                </div>
              </div>
            </div>


            <div className="row justify-content-center">
              <div className="col-12 text-center" style={{ marginTop: '50px' }}>
                <a href="https://agro.agrare.com.br/software-gestao-agricola" target="_blank" className="btn solid-btn">
                  Solicitar Demonstração GRÁTIS</a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Feature;
