import { toast } from 'react-toastify';

export const AlertSuccess = (msg) => (
    toast(msg, { type: 'success', position: 'bottom-center' })
);

export const AlertWarning = (msg) => (
    toast(msg, { type: 'warning', position: 'bottom-center' })
);

export const AlertError = (msg) => (
    toast(msg, { type: 'error', position: 'bottom-center' })
);