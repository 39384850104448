import React from "react";
import ReactGA from 'react-ga';

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="download-section pt-100 background-img"
          style={{
            backgroundImage: "url('img/app-hero-bg.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="download-content text-white pb-100">
                  <h2 className="text-white">
                    Estamos nas principais <br /> lojas de aplicativos
                  </h2>
                  <p className="lead">
                    Nossos aplicativos estão disponível nas principais plataformas móveis.
                  </p>

                  <div className="download-btn">
                    <a href="https://play.google.com/store/apps/details?id=br.com.porthal.agrare" target="_blank" className="btn google-play-btn mr-3" onClick={() => {
                      ReactGA.event({
                        category: 'Download Android',
                        action: 'Clicou em baixar app android'
                      });
                    }}>
                      <span className="ti-android"></span> Google Play
                    </a>
                    <a href="https://apps.apple.com/br/app/agrare/id1449279371" target="_blank" className="btn app-store-btn" onClick={() => {
                      ReactGA.event({
                        category: 'Download iOS',
                        action: 'Clicou em baixar app ios'
                      });
                    }}>
                      <span className="ti-apple"></span> Apple Store
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                    src="img/app-hand-top.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Download;
