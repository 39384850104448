import React, { Component } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "./slide.css"

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

class Slidewithcaption extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            photoIndex: 0,
            isGalleryZoom: false,
            slideLenght: 0,
        };

        this.showZoom = this.showZoom.bind(this);
        this.hideZoom = this.hideZoom.bind(this);
        this.renderCarrossel = this.renderCarrossel.bind(this);
        this.renderImage = this.renderImage.bind(this);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    showZoom = () => {
        this.setState({ isGalleryZoom: true });
    };

    hideZoom = () => {
        this.setState({ isGalleryZoom: false });
    };

    next = (items) => {
        // if (this.animating) return
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1
        this.setState({ activeIndex: nextIndex })
    }

    previous = (items) => {
        // if (this.animating) return
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1
        this.setState({ activeIndex: nextIndex })
    }

    renderCarrossel(galeria) {

        const { activeIndex } = this.state;
        const images = this.renderImage(galeria);
        const slides = [...images];

        return <React.Fragment>
            <AliceCarousel
                activeIndex={activeIndex}
                mouseTracking
                disableDotsControls={this.state.isGalleryZoom ? true : false}
                disableButtonsControls
                autoHeight
                width="50% !important"
                items={slides}
            />
            {this.state.isGalleryZoom ? <div className={"divControles mt-1 mb-2"}>
                <button className={"btn btn-light"} onClick={() => this.previous(slides)}>Anterior</button>
                <nav className={"numberSlide"}>{`${activeIndex + 1} | ${slides.length}`}</nav>
                <button className={"btn btn-light"} onClick={() => this.next(slides)}>Próximo</button>
            </div> : <></>}
        </React.Fragment>
    }

    renderImage(galeria) {
        return galeria.map((item, index) => {
            return (
                <div className="containerFrame">
                    <img
                        src={item}
                        className={"slideFrame " + (this.state.isGalleryZoom ? '' : 'img-central-web')}
                        alt={"Central Agrare"}
                        onClick={() =>
                            this.setState({ isGalleryZoom: true, photoIndex: index })
                        }
                    />
                </div>
            );
        });
    }

    render() {
        const { isGalleryZoom } = this.state;
        const galeria = this.props.galeria;

        return (
            <React.Fragment>
                {this.state.isGalleryZoom ? (
                    <Modal isOpen={isGalleryZoom} size="xl" style={{ maxWidth: '1600px', width: '90%', height: '90vh' }}>
                        <ModalBody className={"zoom"} style={{ paddingBottom: "0" }}>
                            {this.renderCarrossel(galeria)}
                        </ModalBody>
                        <ModalFooter style={{ padding: "10px", justifyContent: "flex-end" }}>
                            <button className="btn btn-light" onClick={this.hideZoom}>
                                Fechar
                            </button>
                        </ModalFooter>
                    </Modal>
                ) : null}
                {this.renderCarrossel(galeria)}
            </React.Fragment>
        );
    }
}

export default Slidewithcaption;