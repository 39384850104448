import React, { Component } from "react";
import _data from "../../data";
import { Modal } from "react-bootstrap";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: {},
      showModalAlexei: false,
      showModalHenrique: false,
    };
  }

  handleShowAlexei = () => {
    this.setState({ showModalAlexei: true });
  };

  handleCloseAlexei = () => {
    this.setState({ showModalAlexei: false });
  };

  handleShowHenrique = () => {
    this.setState({ showModalHenrique: true });
  };

  handleCloseHenrique = () => {
    this.setState({ showModalHenrique: false });
  };

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      testimonial: _data.testimonial
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className={"testimonial-section ptb-100 gray-light-bg"}>
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-12">
                <div className="section-heading mb-5">
                  <h2>
                    Produtores Satisfeitos <br />
                    <span>O que dizem nossos clientes</span>
                  </h2>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="item">
                      <div className="testimonial-quote-wrap">
                        <div className="media author-info mb-3">
                          <img src="img/lorivan.png" className="mr-3 rounded-circle" style={{ width: "60px", height: "60px" }} alt="Lorivan Formighieri" />
                          <div className="media-body">
                            <h5 className="mb-0">Lorivan Formighieri</h5>
                            <span>Fazendas Guabijú, Primavera e São João (Alegrete/RS)</span>
                          </div>
                        </div>
                        <div className="client-say">
                          <p>
                            {" "}
                            <span className='ti-thought'></span>{" "}{" "}{" "}
                            "Há tempos procurava um aplicativo para auxiliar na gestão rural. Porém, os que encontrei eram complexos e exigiam bastante tempo para abastecer os dados. Além de apresentarem informações que eram desnecessárias para meu uso e demandavam tempo. Então procurei algo simples e funcional, que atendesse as minhas expectativas."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="item">
                      <div className="testimonial-quote-wrap">
                        <div className="media author-info mb-3">
                          <img src="img/telio.png" className="mr-3 rounded-circle" style={{ width: "60px", height: "60px" }} alt="Télio Moraes" />
                          <div className="media-body">
                            <h5 className="mb-0">Télio Moraes</h5>
                            <span>Fazenda Agropecuária 2S (Soledade/RS)</span>
                          </div>
                        </div>
                        <div className="client-say">
                          <p>
                            {" "}
                            <span className='ti-thought'></span>{" "}{" "}{" "}
                            "Os benefícios que o Agrare trouxe foi ter as informações que preciso e poder anotar estas em qualquer lugar e hora. Foi poder ver o resultado do trabalho em tempo real, podendo analisar o passado e planejar o futuro."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>

              <div className="col-md-12">
                <div className="row">
                  <div width="100%" height="600px" onClick={this.handleShowHenrique}>
                    <img src="img/dep-henrique.png" alt="img" className="img-fluid" style={{ cursor: 'pointer', borderRadius: '10px' }} />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div width="100%" height="600px" onClick={this.handleShowAlexei}>
                    <img src="img/dep-alexei.png" alt="img" className="img-fluid" style={{ cursor: 'pointer', borderRadius: '10px' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={this.state.showModalAlexei} onHide={this.handleCloseAlexei} size="lg" centered>
            <Modal.Body>
              <iframe width="100%" height="500" src="https://www.youtube.com/embed/62F9zYeHE9Y?autoplay=1&mute=1" title="Depoimento Agrare Gestor Rural" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Modal.Body>
          </Modal>


          <Modal show={this.state.showModalHenrique} onHide={this.handleCloseHenrique} size="lg" centered>
            <Modal.Body>
              <iframe width="100%" height="500" src="https://www.youtube.com/embed/8oNY2GXf4gs?autoplay=1&mute=1" title="Depoimento Agrare Gestor Rural" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Modal.Body>
          </Modal>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonial;
