import React from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import AgencyCoApp from "./reducers";
import Routes from "./routers";
import { toast } from 'react-toastify';
import HttpsRedirect from 'react-https-redirect';

import 'react-toastify/dist/ReactToastify.css';

// create store
const store = createStore(
  AgencyCoApp,
  composeWithDevTools()
  // applyMiddleware(...middleware),
  // other store enhancers if any
);

// optional configuration
const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
}

class App extends React.Component {
  constructor(props) {
    super(props);

    toast.configure();
  }

  render() {
    return (
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
          <HttpsRedirect>
            <Routes />
          </HttpsRedirect>
        </AlertProvider>
      </Provider>
    );
  }
}

export default App;
