import React from "react";
import Slidewithcaption from "../SlideWithCaption";

export default class Screenshots extends React.Component {

  render() {
    return (
      <React.Fragment>
        <section
          id="screenshots"
          className="screenshots-section ptb-100 gray-light-bg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center">
                  <h2>
                    Conheça a plataforma Agrare <br /> <span>Central on-line:</span>
                  </h2>
                  {/* <p className="lead">
                    Credibly synthesize multimedia based networks vis-a-vis
                    top-line growth strategies. Continually leverage existing
                    worldwide interfaces{" "}
                  </p> */}
                </div>
              </div>
            </div>

            <Slidewithcaption galeria={[
              "img/screenshots/web/01.png",
              "img/screenshots/web/02.png",
              "img/screenshots/web/03.png",
              "img/screenshots/web/04.png",
              "img/screenshots/web/05.png",
              "img/screenshots/web/06.png",
              "img/screenshots/web/07.png",
              "img/screenshots/web/08.png",
              "img/screenshots/web/09.png",
              "img/screenshots/web/10.png",
              "img/screenshots/web/11.png",
              "img/screenshots/web/12.png",
              "img/screenshots/web/13.png",
            ]} />

            <br /><br />

            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center">
                  <h2>
                    <span>Aplicativos off-line (Android e iOS)</span>
                  </h2>
                  {/* <p className="lead">
                    Credibly synthesize multimedia based networks vis-a-vis
                    top-line growth strategies. Continually leverage existing
                    worldwide interfaces{" "}
                  </p> */}
                </div>
              </div>
            </div>
            <div className="screen-slider-content mt-5">
              {/* <div className="screenshot-frame"></div> */}
              <div className="screen-carousel owl-carousel owl-theme dot-indicator">
                <img src="img/01.png" className="img-fluid" alt="App Agrare" />
                <img src="img/02.png" className="img-fluid" alt="App Agrare" />
                <img src="img/03.png" className="img-fluid" alt="App Agrare" />
                <img src="img/04.png" className="img-fluid" alt="App Agrare" />
                <img src="img/05.png" className="img-fluid" alt="App Agrare" />
                <img src="img/06.png" className="img-fluid" alt="App Agrare" />
                <img src="img/07.png" className="img-fluid" alt="App Agrare" />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
