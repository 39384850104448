import React, { Component } from "react";
import { Formik, Form } from 'formik';
import { withAlert } from 'react-alert'
import _data from "../../data";
import { InputText } from '../Form/InputText';
import * as Yup from 'yup';
import { InputTextArea } from "../Form/InputTextArea";
import { AlertSuccess, AlertError } from "../AlertToast";

import ReactGA from 'react-ga';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "[empty]",
      load: false,
      expired: "false",
      disableContactBtn: false,
      contactBtnText: "ENVIAR MENSAGEM",
      contact: {}
    };

    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      contact: _data.contact
    });
  }

  handleCaptchaChange = value => {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
  };

  /**
   * When we click on Send button, changeBtnText function will help to change text
   * @param contactBtnText
   */
  changeBtnText = contactBtnText => {
    this.setState({ contactBtnText });
  };

  formSubmit(values) {
    this.changeBtnText("ENVIANDO...");
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        nome: values.nome,
        email: values.email,
        whatsApp: values.telefone,
        empresa: values.empresa,
        mensagem: values.mensagem
      })
    };
    fetch('https://api.sbsonfarm.com:8443/agrare-ws-manager-1.0/sendMail/contato', requestOptions)
      .then(response => response.json())
      .then(() => {
        ReactGA.event({
          category: 'Contato',
          action: 'Solicitou contato'
        });
        values.nome = "";
        values.email = "";
        values.telefone = "";
        values.empresa = "";
        values.mensagem = "";
        this.changeBtnText("ENVIAR MENSAGEM");
        AlertSuccess('Mensagem enviada com sucesso! Aguarde que em breve entraremos em contato.');
      }
      ).catch(() => {
        this.changeBtnText("ENVIAR MENSAGEM");
        AlertError('Sua mensagem não foi enviada. Algo inesperado aconteceu. Tente novamente ou entre em contato via nosso canal de WhatsApp.');
      });
  }

  render() {
    return (
      <React.Fragment>
        <section id="contact" className={"contact-us ptb-100 " + (this.props.bgColor && this.props.bgColor === 'white' ? '' : 'gray-light-bg')}>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="section-heading">
                  <h3>Entre em Contato</h3>
                  <p>Preencha o formulário ou utilize nossos outros canais de atendimento. Ficaremos felizes em lhe atender.</p>
                </div>
                <div className="footer-address">
                  <h6>
                    <strong>{this.state.contact.office}</strong>
                  </h6>
                  <p>{this.state.contact.address}</p>
                  <ul>
                    <li>
                      <span>
                        E-mail :{" "}
                        <a href="mailto:contato@agrare.com.br" style={{ color: '#1C8E42' }}>
                          contato@agrare.com.br
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
                <br /> <br />
                <div className="section-heading">
                  <h3>Siga o @agrareoficial</h3>
                  <p>Saiba das novidades e receba dicas na nossas redes sociais.</p>
                </div>
              </div>


              <div className="col-md-7">
                <Formik
                  enableReinitialize
                  initialValues={{ nome: '', email: '', telefone: '', empresa: '', mensagem: '', recaptcha: '' }}
                  validationSchema={
                    Yup.object().shape({
                      nome: Yup.string()
                        .min(2, 'Muito curto!')
                        .max(50, 'Campo deve ter menos do que 50 caracteres!')
                        .required('Campo Obrigatório'),
                      email: Yup.string()
                        .email('E-mail Inválido')
                        .max(50, 'Campo deve ter menos do que 50 caracteres!')
                        .required('Campo Obrigatório'),
                      telefone: Yup.string().matches(phoneRegExp, 'Número inválido')
                        .min(10, 'Número inválido')
                        .required('Campo Obrigatório'),
                      empresa: Yup.string()
                        .min(2, 'Muito curto!')
                        .max(50, 'Campo deve ter menos do que 50 caracteres!')
                        .required('Campo Obrigatório'),
                      mensagem: Yup.string()
                        .min(2, 'Informe sua mensagem!')
                        .max(4000, 'Campo deve ter menos do que 4000 caracteres!')
                        .required('Campo Obrigatório'),
                      // recaptcha: Yup.string().required(),
                    })
                  }
                  onSubmit={(values) => this.formSubmit(values)}
                >
                  {(errors, touched, setFieldValue) => (
                    <Form autoComplete="off" name="form_contato">
                      <div className="row">
                        <InputText name='nome' placeholder='Informe seu Nome' col={6} />
                        <InputText name='email' placeholder='Informe seu E-mail' col={6} />
                      </div>
                      <div className="row">
                        <InputText name='telefone' placeholder='Informe seu WhatsApp' type="number" col={6} />
                        <InputText name='empresa' placeholder='Informe onde você trabalha' col={6} />
                      </div>
                      <div className="row">
                        <InputTextArea name='mensagem' placeholder='Informe sua Mensagem' />
                      </div>
                      <div className="row">
                        <div className="col-sm-5 mt-3">
                          <button
                            type="submit"
                            className="btn solid-btn"
                            id="btnContactUs"
                            disabled={this.state.disableContactBtn}
                            onClick={() => { }}
                          >
                            {this.state.contactBtnText}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <p className="form-message"></p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

// export default connect(state => ({
//   state
// }))(Contact);
export default withAlert()(Contact);
